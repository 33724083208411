import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
var mixpanel = require('mixpanel-browser')

export default class extends Controller {
    static values = {
        refresh: Number,
        token: String
    }

    connect() {
        let controller                = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
    }

    init_mixpanel(token) {
        mixpanel.init(token, { ignore_dnt: true });
    }

    send_event(event) {
        if(mixpanel.config) {
            var event_name = event.target.getAttribute('data-event-name')
            var hash = event.target.getAttribute('data-hash')
            if (hash) {
                mixpanel.track(event_name, JSON.parse(hash));
            } else {
                mixpanel.track(event_name);
            }
        }
    }

    refreshValueChanged() {
        if(this.tokenValue) {
            if(this.refreshValue === 1) {
                this.init_mixpanel(this.tokenValue)
                this.refreshValue = 0
            }
        }
    }
}