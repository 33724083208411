import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs";

export default class extends Controller {
    static values = {
        event: String,
        invoiceRecipientId: String,
        refresh: Number,
        clientSecret: String,
        paymentIntentId: String,
        stripeAccount: String,
        stripeToken: String
    }

    connect() {
        this.element[this.identifier] = this;
        StimulusReflex.register(this)
    }

    submit(event) {
        $('#submitButton').prop('disabled', true);
        document.querySelector('[data-controller="modal-form"]').dataset.modalFormKeepOpenValue = true
        event.preventDefault();
        if (!$("#flexSwitchCheckDefaultProcessCard").is(':checked')) {
            $("#cardTokenInput").remove();
        }
        this.stimulate(this.eventValue, event.target)
    }

    createTokenInput(value) {
        $("#cardTokenInput").remove();
        let form = document.getElementById('payment-form');
        let tokenInput = document.createElement("input");
        tokenInput.setAttribute('name', 'card_token');
        tokenInput.setAttribute('id', 'cardTokenInput');
        tokenInput.setAttribute('type', 'hidden');
        tokenInput.setAttribute('value', value);
        form.appendChild(tokenInput);
        return form;
    }

    clientSecretValueChanged() {
        let controller = this;
        if (this.clientSecretValue == '') return;
        let stimulateHandler = () => {
            $('#submitButton').prop('disabled', false)
        }

        window.stripe.confirmCardPayment(this.clientSecretValue, {
            payment_method: {
                card: window.cardNumber,
            }
        }).then(function (result) {
            var displayError = document.getElementById('credit-error');
            if (result.error) {
                displayError.textContent = result.error.message;
            } else {
                displayError.textContent = '';
            }
            if (result.error) {
                controller.createTokenInput('validation');
                $('#submitButton').prop('disabled', false);
                document.querySelector('[data-controller="modal-form"]').dataset.modalFormKeepOpenValue = true
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    let form = controller.createTokenInput(result.paymentIntent.id);
                    controller.stimulate(controller.eventValue, form).then(stimulateHandler);
                }
            }
        });
    }

    refreshValueChanged() {
        if (this.refreshValue == 1) {
            this.refreshValue = 0;
            this.createTokenInput('validation');
            window.stripe = Stripe(this.stripeTokenValue, {
                stripeAccount: this.stripeAccountValue,
            });
            var style = {
                base: {
                    iconColor: '#666EE8',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '18px',
                    lineHeight: '24px',
                    '::placeholder': {
                        color: '#c4d1d7',
                    },
                },
            }
            window.elements = window.stripe.elements();
            window.cardNumber = window.elements.create('cardNumber', {style: style})
            window.cardNumber.mount('#cardNumber');
            var expiry = window.elements.create('cardExpiry', {style: style})
            expiry.mount('#expDate')
            var cvc = window.elements.create('cardCvc', {style: style})
            cvc.mount('#cvc')

            cvc.on('change', function (event) {
                $('#submitButton').prop('disabled', false);
                document.querySelector('[data-controller="modal-form"]').dataset.modalFormKeepOpenValue = true
                var displayError = document.getElementById('cvv-error');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

            expiry.on('change', function (event) {
                $('#submitButton').prop('disabled', false);
                document.querySelector('[data-controller="modal-form"]').dataset.modalFormKeepOpenValue = true
                var displayError = document.getElementById('expiry-error');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

            window.cardNumber.on('change', function (event) {
                $('#submitButton').prop('disabled', false);
                document.querySelector('[data-controller="modal-form"]').dataset.modalFormKeepOpenValue = true
                var displayError = document.getElementById('credit-error');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });
        }
    }
}
