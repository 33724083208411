const warningatorPlugin = function (pluginConfig) {
    var defaultConfig = {
        text: 'Choose the year first',
        theme: "light",
        warningYear: 1900,
    };

    var config = {};
    for (var key in defaultConfig) {
        config[key] = pluginConfig && pluginConfig[key] !== undefined ? pluginConfig[key] : defaultConfig[key];
    }

    return function (fp) {
        fp.yearWarningatorContainer = fp._createElement(
            "div",
            "flatpickr-warningator " + config.theme + "Theme",
            '',
        );
        fp.yearWarningatorContainer.innerHTML = config.text;
        fp.yearWarningatorContainer.style.display = 'none';
        fp.yearWarningatorContainer.tabIndex = -1;

        fp.config.onYearChange.push(function() {
            if (fp.currentYear <= config.warningYear) {
                fp.yearWarningatorContainer.style.display = 'block';
            } else {
                fp.yearWarningatorContainer.style.display = 'none';
            }
        })

        return {
            onReady: function onReady() {
                const el = fp.monthNav.parentNode;
                el.insertBefore(fp.yearWarningatorContainer, fp.monthNav);
                console.log('YEAR: ',fp.currentYear);
                console.log(fp.selectedDates)
                if(fp.selectedDates.length < 1) {
                    let selectedDate = new Date()
                    selectedDate.setFullYear(1900)
                    fp.jumpToDate(selectedDate)
                }
                if (fp.currentYear <= config.warningYear) {
                    fp.yearWarningatorContainer.style.display = 'block';
                }
            }
        };
    };
}

export default warningatorPlugin
