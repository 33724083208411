import Trix from "../misc/trix";

// see https://github.com/basecamp/trix/blob/main/src/trix/config/toolbar.coffee for full reference
document.addEventListener("trix-before-initialize", () => {

Trix.config.textAttributes.myCustomButton = {
  tagName: 'custom-button',
  style: {
      background: '#3397ac',
      padding: '0.375rem 0.75rem',
      borderRadius: '4px',
      color: 'white',
      fontSize: '1.125rem',
      border: '1px solid transparent'
  },
  inheritable: true
};
Trix.config.blockAttributes.alignLeft = {
    tagName: 'div',
    className: 'align-left',
    nestable: false,
    exclusive: true,
};

Trix.config.blockAttributes.alignCenter = {
    tagName: 'div',
    className: 'align-center',
    nestable: false,
    exclusive: true,
};

Trix.config.blockAttributes.alignRight = {
    tagName: 'div',
    className: 'align-right',
    nestable: false,
    exclusive: true,
};

Trix.config.toolbar = {
    getDefaultHTML: () => {
        const lang = Trix.config.lang;
        const reflexData = document.querySelector('#wysiwyg');
        return (`
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--alignment-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Center" tabindex="-1">Align Center</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
        </span>
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
          <button type="button" class="trix-button" data-trix-attribute="myCustomButton" title="Button" tabindex="-1">Button</button>
        </span>
        <span class="trix-button-group-spacer"></span>
        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1" disabled="">Undo</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1" disabled="">Redo</button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
    `)
    }
}


  addEventListener("trix-initialize", event => {
    const { toolbarElement } = event.target
    const inputElement = toolbarElement.querySelector("input[name=href]")
    inputElement.type = "text"
    inputElement.pattern = ".+"
    $('trix-editor').on('click', function(e) {
      e.preventDefault();
    })
  })
})
// attached icon
// <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
//   <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
//</span>
